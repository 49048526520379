export const offices = [
  {
    name: "Registered Office",
    address: "A-417 okhla phase 1, south delhi , new delhi. 110020",
  },
  {
    name: "Head Office",
    address: `Shakti Darshan,
  9G5R+V9W, Godam Rd, Bhowali Range, bhimtal Uttarakhand 263136`,
  },
  {
    name: "Eastern zone office",
    address:
      "Eastern zone office. Syama comitee market, 5th floor, Near devi gajadhar chak devi mandir golapar, 801503 patna bihar",
  },
];
