export const galleryImages = [
  {
    url: "/images/tour/bt.jpeg",
  },
  {
    url: "/images/tour/bt1.jpeg",
  },
  {
    url: "/images/tour/bt2.jpeg",
  },
  {
    url: "/images/tour/bt3.jpeg",
  },
  {
    url: "/images/tour/bt4.jpeg",
  },
  {
    url: "/images/tour/bt5.jpeg",
  },
  {
    url: "/images/tour/bt6.jpeg",
  },
  {
    url: "/images/tour/bt7.jpeg",
  },
  {
    url: "/images/tour/bt8.jpeg",
  },
  {
    url: "/images/tour/bt9.jpeg",
  },
  {
    url: "/images/tour/bt10.jpeg",
  },
  {
    url: "/images/tour/bt11.jpeg",
  },
  {
    url: "/images/tour/bt12.jpeg",
  },
  {
    url: "/images/tour/bt13.jpeg",
  },
  {
    url: "/images/tour/bt14.jpeg",
  },
  {
    url: "/images/tour/bt15.jpeg",
  },
  {
    url: "/images/tour/bt16.jpeg",
  },
  {
    url: "/images/tour/bt17.jpeg",
  },

  {
    url: "/images/tour/bt19.jpeg",
  },
  {
    url: "/images/tour/bt20.jpeg",
  },
  {
    url: "/images/tour/bt21.jpeg",
  },
  {
    url: "/images/tour/bt22.jpeg",
  },
  {
    url: "/images/tour/bt23.jpeg",
  },
  {
    url: "/images/tour/bt24.jpeg",
  },
  {
    url: "/images/tour/bt25.jpeg",
  },
  {
    url: "/images/tour/bt26.jpeg",
  },
  {
    url: "/images/tour/bt27.jpeg",
  },
  {
    url: "/images/tour/kedarTour1.jpeg",
  },
  {
    url: "/images/tour/kedarTour2.jpeg",
  },
  {
    url: "/images/tour/kedarTour3.jpeg",
  },
  {
    url: "/images/tour/kedarTour4.jpeg",
  },
  {
    url: "/images/tour/kedarTour5.jpeg",
  },
];
