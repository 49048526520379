import "./App.css";
import AppComponent from "./pages/AppComponent/AppComponent";

function App() {
  return (
    <div className="App">
      <AppComponent></AppComponent>
    </div>
  );
}

export default App;
